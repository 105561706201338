import { BaseService } from './base.service';

export class AutoEvaluationsService extends BaseService {
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super();
  }

  downloadQaEvaluation({ id }) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/auto-evaluations/download/${id}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  downloadEvaluationResult({ id }) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/auto-evaluations/download-bilan/${id}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  searchEvaluation(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/auto-evaluations/search', data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  searchTypeEvaluation(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/type-auto-evaluations/search', data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  updateEvaluation(object) {
    console.log('{ id, params }', object);
    const { id, params } = object;
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .put(`/auto-evaluations/${id}`, params)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  // --------------------------------------------------

  getTypeEvaluation(id) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/type-auto-evaluations/${id}`, {})
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  getEvaluation(id) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/auto-evaluations/${id}`, {})
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  createEvaluation(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/auto-evaluations', data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  deleteEvaluation(id) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .delete(`/auto-evaluations/${id}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  // ---------------------------------------
  addEvaluation(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/formulaire-evaluations', data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  allEvaluation() {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/formulaire-evaluations', {})
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  allTypeEvaluation() {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/type-auto-evaluations', {})
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  searchFormEvaluation(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/formulaire-evaluations/search', data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  updateFormEvaluation(object) {
    const { id, params } = object;
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .put(`/formulaire-evaluations/${id}`, params)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
}

export default new AutoEvaluationsService();
