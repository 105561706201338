import evaluationsService from '@/services/http/evaluations.service';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async downloadQaEvaluation({ rootState }, data) {
      const res = await evaluationsService.downloadQaEvaluation(data);
      return res;
    },
    async downloadEvaluationResult({ rootState }, data) {
      const res = await evaluationsService.downloadEvaluationResult(data);
      return res;
    },
    async searchEvaluation({ rootState }, data) {
      const res = await evaluationsService.searchEvaluation(data);
      return res;
    },
    async searchTypeEvaluation({ rootState }, data) {
      const res = await evaluationsService.searchTypeEvaluation(data);
      return res;
    },
    async getEvaluation({ rootState }, data) {
      const res = await evaluationsService.getEvaluation(data);
      return res;
    },
    async getTypeEvaluation({ rootState }, data) {
      const res = await evaluationsService.getTypeEvaluation(data);
      return res;
    },

    async createEvaluation({ rootState }, data) {
      const res = await evaluationsService.createEvaluation(data);
      return res;
    },

    async updateEvaluation({ rootState }, data) {
      const res = await evaluationsService.updateEvaluation(data);
      return res;
    },

    async deleteEvaluation({ rootState }, data) {
      const res = await evaluationsService.deleteEvaluation(data);
      return res;
    },

    async addEvaluation({ rootState }, data) {
      const res = await evaluationsService.addEvaluation(data);
      return res;
    },

    async allTypeEvaluation({ rootState }, data) {
      const res = await evaluationsService.allTypeEvaluation();
      return res;
    },

    async allEvaluation({ rootState }, data) {
      const res = await evaluationsService.allEvaluation();
      return res;
    },

    async searchFormEvaluation({ rootState }, data) {
      const res = await evaluationsService.searchFormEvaluation(data);
      return res;
    },

    async updateFormEvaluation({ rootState }, data) {
      const res = await evaluationsService.updateFormEvaluation(data);
      return res;
    },
  },
};
