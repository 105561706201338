<script>
import { BFormInput } from 'bootstrap-vue'
export default {
  name: 'BInputX',
  components: {
    BFormInput,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  mounted() {
    this.valuex = this.value
  },
  data() {
    return {
      valuex: '',
    }
  },
}
</script>

<template>
  <b-form-input
    v-model="valuex"
    @change="
      () => {
        $emit('changeVal', valuex)
      }"
  />
</template>
