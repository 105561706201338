<template>
  <div>
    <!-- data = {{ data }} -->
    <b-card class="invoice-preview-card">
      <div v-if="isLoading" class="d-flex justify-content-center">
        <span>
          <b-spinner :variant="'primary'" class="mt-1" type="grow" />
        </span>
      </div>
      <div v-else class="d-flex justify-content-between flex-wrap">
        <h2 class="my-auto">
          <!-- {{ $t("configuration.evalations_forms_details.title") }} <br /> -->
          {{ currentFormTitle }} |
          {{ currentFormType === 'evaluation' ? 'Évaluation' : 'Examen' }}
        </h2>
        <b-button
          class="mt-1 float-right my-auto col-3"
          :disabled="isLoading"
          variant="primary"
          @click="
            () => {
              goBack()
            }
          "
        >
          <i class="las la-arrow-left mr-25" />
          <span>{{ $t('button.return') }}</span>
        </b-button>
      </div>
    </b-card>

    <b-card v-if="!isLoading">
      <!-- wizard -->
      <form-wizard
        ref="current-form-wizard"
        color="#0e6158"
        shape="square"
        step-size="xs"
        :startIndex="0"
        :title="null"
        :subtitle="null"
        :next-button-text="'Chapitre suivant'"
        :back-button-text="'Chapitre précédent'"
        class="steps-transparent"
        @on-complete="
          () => {
            sendEvaluation()
          }
        "
      >
        <!-- finish button template -->
        <template #finish="{ next, previous, canFinish }">
          <button
            @click="
              () => {
                update_form()
                goBack()
              }
            "
            type="button"
            class="btn btn-primary"
          >
            <span>Enregistrer les modifications</span>
          </button>
        </template>

        <div style="max-width: 1800px" class="mb-4 mx-auto">
          <tab-content
            v-for="(chapter, chapter_index) in current_form.data.chapters"
            :key="chapter_index"
            icon="feather icon-file-text"
            :title="chapter.label"
            class="p-0"
          >
            <app-collapse accordion type="border">
              <!-- action button  -->
              <div class="d-flex justify-content-between">
                <!-- chapter title -->
                <b-form-group class="col-4 nopadding">
                  <b-input-group size="sm">
                    <!-- <b-form-input
                        placeholder="Nom du chapitre"
                        v-model.lazy="item.label"
                    /> -->
                    <b-input-x
                      :value="chapter.label"
                      @changeVal="
                        ($event) => {
                          current_form.data.chapters[chapter_index].label =
                            $event
                        }
                      "
                    />
                    <!-- @input="
                        () => {
                          useDebounceFn(update_form(), 5000)
                        }
                      " -->
                    <b-input-group-append
                      v-b-tooltip.hover.top="
                        'Vous pouvez modifier le titre du chapitre.'
                      "
                      is-text
                    >
                      <i class="las la-info-circle iconMedium" />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <!-- action buttons -->
                <div class="my-auto">
                  <!-- ajouter le chapitre -->
                  <b-button
                    v-b-tooltip.hover.top.v-primary="
                      'Ajouter un chapitre après celui-ci.'
                    "
                    variant="gradient-primary"
                    class="btn-icon ml-3"
                    size="sm"
                    @click.stop="add_chapter(chapter_index)"
                  >
                    Ajouter un chapitre <i class="las la-plus" />
                  </b-button>
                  <!-- supprimer le chapitre -->
                  <b-button
                    v-b-tooltip.hover.top="'Supprimer le chapitre.'"
                    variant="gradient-danger"
                    class="btn-icon ml-1"
                    size="sm"
                    @click.stop="remove_chapter(chapter_index)"
                  >
                    Supprimer ce chapitre <i class="las la-trash" />
                  </b-button>
                </div>
              </div>
              <!-- end action button -->
              <hr style="border-top: 1px solid var(--primary)" />
              <span class="d-flex justify-content-start mt-1">
                <strong>SECTIONS</strong>
              </span>
              <custom-app-collapse-item
                v-for="(secItem, secIndex) in current_form.data.chapters[
                  chapter_index
                ].sections"
                :id="secItem.id"
                :key="secIndex"
                :title="secItem.label"
                add-and-delete-section="true"
                class="mt-2"
                @addSection="
                  ($event) => {
                    add_section(secIndex, chapter_index)
                  }
                "
                @removeSection="
                  ($event) => {
                    remove_section(secIndex, chapter_index)
                  }
                "
                @onTitleChange="
                  ($event) => {
                    section_change_title($event, secIndex, chapter_index)
                  }
                "
              >
                <!-- class="table table-bordered" -->
                <b-table
                  ref="refAutoEvaTable"
                  :items="secItem.questions"
                  responsive
                  :fields="tableColumnsQuestion"
                  :primary-key="uniqId"
                  show-empty
                  fixed
                  empty-text="Aucune donnée"
                  :busy="isLoading"
                  hover
                  class="table table-bordered"
                >
                  <template #table-busy>
                    <div class="text-center my-2">
                      <b-spinner class="align-middle" />
                    </div>
                  </template>
                  <!-- question -->
                  <template #cell(question)="dataRes">
                    <b-input-x
                      :value="dataRes.item.question"
                      @changeVal="
                        ($event) => {
                          dataRes.item.question = $event
                        }
                      "
                    />
                    <span class="d-flex justify-content-center mt-1">
                      <strong>META DONNÉES DE LA QUESTION</strong>
                    </span>
                    <div class="row">
                      <b-input-group class="col-12 w-100">
                        <b-input-group-prepend
                          class="w-100 d-flex justify-content-between"
                        >
                          <div>
                            <div class="w-100 my-auto">Type:</div>
                            <b-form-select
                              v-model="dataRes.item.metadata.type"
                              :options="option_meta_type"
                              html-field="text"
                              value-field="value"
                              size="sm"
                            />
                          </div>
                          <div>
                            <div class="w-100 my-auto">Est requit:</div>
                            <b-form-select
                              v-model="dataRes.item.metadata.is_required"
                              :options="option_true_fale"
                              html-field="text"
                              value-field="value"
                              size="sm"
                            />
                          </div>
                          <div>
                            <div class="w-100 my-auto">A justifier:</div>
                            <b-form-select
                              v-model="dataRes.item.metadata.to_justify"
                              :options="option_true_fale"
                              html-field="text"
                              value-field="value"
                              size="sm"
                            />
                          </div>
                          <div>
                            <div class="pl-1 w-100 my-auto">A tester:</div>
                            <b-form-select
                              v-model="dataRes.item.metadata.to_test"
                              :options="option_true_fale"
                              html-field="text"
                              value-field="value"
                              size="sm"
                            />
                          </div>
                        </b-input-group-prepend>
                      </b-input-group>
                    </div>
                  </template>
                  <!-- responses -->
                  <template #cell(responses)="dataRes">
                    <!-- reponse type -->
                    <span class="d-flex justify-content-between mt-1">
                      <span> <strong>TYPE DE RÉPONSE</strong></span>
                      <span>
                        <b-form-select
                          v-model="dataRes.item.input_type"
                          :options="option_input_type"
                          html-field="text"
                          value-field="value"
                          size="sm"
                        />
                      </span>
                    </span>
                    <hr />
                    <!-- response options -->
                    <div v-if="dataRes.item.input_type === 'RADIO'">
                      <div
                        v-for="(res, r_index) in dataRes.item.responses"
                        :key="r_index"
                        class="row"
                      >
                        <b-input-group
                          class="col-12 w-100"
                          :class="r_index !== 0 && 'mt-1'"
                        >
                          <b-input-group-prepend class="w-100">
                            <span class="w-100 my-auto"
                              >Label: {{ res.id }}</span
                            >
                            <b-form-input
                              v-model="res.label"
                              size="sm"
                              type="text"
                            />
                            <span class="pl-1 w-100 my-auto">Valeur: </span>
                            <b-form-input
                              v-model="res.value"
                              v-digits-only="{ length: 10 }"
                              size="sm"
                            />
                            <!-- ajouter une réponse -->
                            <b-button
                              v-b-tooltip.hover.top.v-primary="
                                'Ajouter une réponse après celle-ci.'
                              "
                              variant="gradient-primary"
                              class="btn-icon ml-3"
                              size="sm"
                              @click="
                                add_response(
                                  r_index,
                                  dataRes.index,
                                  secIndex,
                                  chapter_index
                                )
                              "
                            >
                              <i class="las la-plus" />
                            </b-button>
                            <!-- supprimer la réponse -->
                            <b-button
                              v-b-tooltip.hover.top="'Supprimer la réponse.'"
                              variant="gradient-danger"
                              class="btn-icon ml-1"
                              size="sm"
                              @click="
                                remove_response(
                                  r_index,
                                  dataRes.index,
                                  secIndex,
                                  chapter_index
                                )
                              "
                            >
                              <i class="las la-trash" />
                            </b-button>
                          </b-input-group-prepend>
                        </b-input-group>
                      </div>
                    </div>
                  </template>
                  <!-- actions -->
                  <template #cell(actions)="dataRes">
                    <div class="d-flex justify-content-betwenn">
                      <!-- ajouter une question -->
                      <b-button
                        v-b-tooltip.hover.top.v-primary="
                          'Ajouter une question après celle-ci.'
                        "
                        variant="gradient-primary"
                        class="btn-icon"
                        size="sm"
                        @click="
                          add_question(dataRes.index, secIndex, chapter_index)
                        "
                      >
                        <i class="las la-plus" />
                      </b-button>
                      <!-- supprimer la question -->
                      <b-button
                        v-b-tooltip.hover.top="'Supprimer la question.'"
                        variant="gradient-danger"
                        class="btn-icon ml-1"
                        size="sm"
                        @click="
                          remove_question(
                            dataRes.index,
                            secIndex,
                            chapter_index
                          )
                        "
                      >
                        <i class="las la-trash" />
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </custom-app-collapse-item>
            </app-collapse>
          </tab-content>
        </div>
      </form-wizard>
    </b-card>
  </div>
</template>

<script>
import {
  BTable,
  BCard,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormTextarea,
  BButton,
  BFormCheckbox,
  BModal,
  VBModal,
  BAlert,
  BCardText,
  BFormRadioGroup,
  BSpinner,
  BCardHeader,
  BCardBody,
  BTabs,
  BTab,
  VBTooltip,
} from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import BInputX from '@/components/input/BInputX.vue'
import vSelect from 'vue-select'
import CustomAppCollapseItem from '@core/components/app-collapse/CustomAppCollapseItem.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions /*mapGetters, mapMutations*/ } from 'vuex'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import { VueEditor } from 'vue2-editor'

// -------- store modules and vuex utilities
import evaluationsStoreModule from '@/store/evaluations'
import secteursStoreModule from '@/store/secteurs'
// import appConfigurationStroreModule from '@/store/app-configuration'

import {
  registerStoreModule,
  // unregisterStoreModule,
} from '@/helpers/vuex-utils'
import { useDebounceFn } from '@vueuse/core'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    quillEditor,
    BTable,
    BCard,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormTextarea,
    BButton,
    BFormCheckbox,
    BModal,
    vSelect,
    VBModal,
    BAlert,
    BSpinner,
    BCardText,
    BFormRadioGroup,
    BCardHeader,
    BTabs,
    BTab,
    BCardBody,
    VueEditor,
    ToastificationContent,
    AppCollapse,
    AppCollapseItem,
    CustomAppCollapseItem,
    FormWizard,
    TabContent,
    BInputX,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      nexus: false,
      current_form: {},
      current_id: null,
      currentFormTitle: null,
      currentFormType: null,
      current_type_hebergement_id: null,
      isLoading: false,
      filterBy: {
        type_auto_evaluation_id: null,
        secteur_id: null,
      },
      options_type_evaluation: [
        { title: 'Évaluation', value: 'evaluation' },
        { title: 'Examen', value: 'examen' },
      ],
      tableColumns: [
        {
          key: 'title',
          label: 'Libellé',
          sortable: true,
        },
        {
          key: 'secteur_id',
          label: 'Secteur',
          sortable: true,
        },
        {
          key: 'type',
          label: 'Type',
          sortable: true,
        },
        {
          key: 'created_at',
          label: 'Date',
          sortable: true,
        },
        {
          key: 'action',
          label: 'Actions',
        },
      ],
      typeAutoEvaluationData: [],
      option_meta_type: [
        {
          text: 'Majeur',
          value: 'MAJOR',
        },
        {
          text: 'Mineur',
          value: 'MINOR',
        },
      ],
      option_true_fale: [
        {
          text: 'Oui',
          value: true,
        },
        {
          text: 'Non',
          value: false,
        },
      ],
      option_rang: [
        {
          text: '1 étoile',
          value: '1',
        },
        {
          text: '2 étoile',
          value: '2',
        },
        {
          text: '3 étoile',
          value: '3',
        },
        {
          text: '4 étoile',
          value: '4',
        },
        {
          text: '5 étoile',
          value: '5',
        },
      ],
      option_input_type: [
        {
          text: 'Liste à choix unique',
          value: 'RADIO',
        },
        {
          text: 'Champ de texte',
          value: 'TEXT-INPUT',
        },
      ],
      listSectorsData: [],
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 10,
        metaData: { from: 0, to: 0 },
      },
      tableColumnsQuestion: [
        {
          key: 'question',
          label: 'Critères',
        },
        {
          key: 'responses',
          label: 'Réponses',
        },
        {
          key: 'Actions',
          label: 'Actions',
          thStyle: { width: '10%' },
        },
      ],
    }
  },
  computed: {
    data() {
      return this.current_form.data
    },
    uniqId() {
      return uuidv4()
    },
  },
  watch: {
    $route: {
      immediate: true,
      async handler(val, old) {
        await this.load_data()
      },
    },
    current_form: {
      deep: true,
      handler(val, old) {
        useDebounceFn(this.update_form(), 5000)
      },
    },
  },
  setup() {
    const requiredStoreModules = [
      { path: 'evaluations', module: evaluationsStoreModule },
      { path: 'secteurs', module: secteursStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)

    return { requiredStoreModules }
  },
  mounted() {},
  methods: {
    ...mapActions('evaluations', {
      action_getTypeEvaluation: 'getTypeEvaluation',
      action_updateEvaluation: 'updateEvaluation',
      action_updateFormEvaluation: 'updateFormEvaluation',
    }),

    // ------------------------------------------------------------------
    // Load data
    async load_data() {
      try {
        const { id, type_hebergement_id } = this.$route.params
        this.current_id = id
        this.isLoading = true

        await this.action_getTypeEvaluation(id)
          .then(async (response) => {
            if (response.status === 200) {
              if (type_hebergement_id !== undefined) {
                this.current_type_hebergement_id = type_hebergement_id
              }
              this.current_form = await this.find_correct_form(
                response.data,
                this.current_type_hebergement_id
              )
              this.getCurrentTypeEvaluationTitle(
                this.current_form.type_auto_evaluation_id
              )
              this.isLoading = false
            }
          })
          .catch((error) => {
            this.isLoading = false
            console.error(
              'Erreur lors de la recherche des auto-évaluations.',
              error
            )
          })
      } catch (error) {
        this.isLoading = false
        console.error(
          'Erreur lors de la recherche des auto-évaluations.',
          error
        )
      }
    },
    sendEvaluation() {},

    // ------------------------------------------------------------------
    // response actions
    add_response(response_index, question_index, section_index, chapter_index) {
      const id = this.findMoreBigId(
        this.current_form.data.chapters[chapter_index].sections[section_index]
          .questions[question_index].responses
      )
      const response = { id: id + 1, label: 'label ', value: 0 }
      this.current_form.data.chapters[chapter_index].sections[
        section_index
      ].questions[question_index].responses.splice(
        response_index + 1,
        0,
        response
      )
    },
    remove_response(
      response_index,
      question_index,
      section_index,
      chapter_index
    ) {
      // console.log(
      //   "response_index, question_index, section_index, chapter_index::: 🔴",
      //   response_index,
      //   question_index,
      //   section_index,
      //   chapter_index
      // );
      if (
        this.current_form.data.chapters[chapter_index].sections[section_index]
          .questions[question_index].responses.length === 1
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Le question doit avoir au moins une réponse.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } else {
        this.current_form.data.chapters[chapter_index].sections[
          section_index
        ].questions[question_index].responses.splice(response_index, 1)
      }
    },
    // questions actions  --------------------
    add_question(question_index, section_index, chapter_index) {
      // console.log(
      //   "question_index, section_index, chapter_index::: 🟢",
      //   question_index,
      //   section_index,
      //   chapter_index
      // );
      const id = this.findMoreBigId(
        this.current_form.data.chapters[chapter_index].sections[section_index]
          .questions
      )
      const question = {
        id: id + 1,
        input_type: 'RADIO',
        question: 'Titre de la question',
        responses: [
          { id: 1, label: 'Non', value: 0 },
          { id: 2, label: 'Oui', value: 1 },
        ],
        selected_id: null,
        metadata: [
          {
            type: 'MAJOR',
            is_required: true,
            to_justify: false,
            // to_test: false,
            rank: '1',
          },
          {
            type: 'MINOR',
            is_required: true,
            to_justify: false,
            // to_test: false,
            rank: '1',
          },
          {
            type: 'NOT_APPLICABLE',
            is_required: true,
            to_justify: false,
            // to_test: false,
            rank: '1',
          },
          {
            type: 'NOT_APPLICABLE',
            is_required: true,
            to_justify: false,
            // to_test: false,
            rank: '1',
          },
          {
            type: 'MAJOR',
            is_required: true,
            to_justify: false,
            // to_test: false,
            rank: '1',
          },
        ],
      }
      this.current_form.data.chapters[chapter_index].sections[
        section_index
      ].questions.splice(question_index + 1, 0, question)
    },
    remove_question(question_index, section_index, chapter_index) {
      // console.log(
      //   "question_index, section_index, chapter_index::: 🔴",
      //   question_index,
      //   section_index,
      //   chapter_index
      // );
      if (
        this.current_form.data.chapters[chapter_index].sections[section_index]
          .questions.length === 1
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Le section doit avoir au moins une question.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } else {
        this.current_form.data.chapters[chapter_index].sections[
          section_index
        ].questions.splice(question_index, 1)
      }
    },
    // sections actions --------------------
    add_section(section_index, chapter_index) {
      const id = this.findMoreBigId(
        this.current_form.data.chapters[chapter_index].sections
      )
      const section = {
        id: id + 1,
        label: 'Nom de la section',
        total_points: { total_responded_questions: null },
        questions: [
          {
            id: id + 1,
            input_type: 'RADIO',
            question: 'Titre de la question',
            responses: [
              { id: 1, label: 'Non', value: 0 },
              { id: 2, label: 'Oui', value: 1 },
            ],
            selected_id: null,
            metadata: [
              {
                type: 'MAJOR',
                is_required: true,
                to_justify: false,
                // to_test: false,
                rank: '1',
              },
              {
                type: 'MINOR',
                is_required: true,
                to_justify: false,
                // to_test: false,
                rank: '1',
              },
              {
                type: 'NOT_APPLICABLE',
                is_required: true,
                to_justify: false,
                // to_test: false,
                rank: '1',
              },
              {
                type: 'NOT_APPLICABLE',
                is_required: true,
                to_justify: false,
                // to_test: false,
                rank: '1',
              },
              {
                type: 'MAJOR',
                is_required: true,
                to_justify: false,
                // to_test: false,
                rank: '1',
              },
            ],
          },
        ],
      }
      this.current_form.data.chapters[chapter_index].sections.splice(
        section_index + 1,
        0,
        section
      )
    },
    remove_section(section_index, chapter_index) {
      // console.log(
      //   " section_index, chapter_index::: 🔴",
      //   section_index,
      //   chapter_index
      // );
      if (
        this.current_form.data.chapters[chapter_index].sections.length === 1
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Le chapitre doit avoir au moins une section.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } else {
        this.current_form.data.chapters[chapter_index].sections.splice(
          section_index,
          1
        )
      }
    },
    section_change_title(label, section_index, chapter_index) {
      this.current_form.data.chapters[chapter_index].sections[
        section_index
      ].label = label
    },
    section_change_chapter_title(label, chapter_index) {
      this.current_form.data.chapters[chapter_index].label = label
    },
    // chapters actions  --------------------
    add_chapter(chapter_index) {
      this.isLoading = true
      const id = this.findMoreBigId(this.current_form.data.chapters)
      const chapter = {
        n: id + 1,
        label: 'Nom du chapitre',
        sections: [
          {
            id: 1,
            label: 'Nom de la section',
            total_points: { total_responded_questions: null },
            questions: [
              {
                id: id + 1,
                input_type: 'RADIO',
                question: 'Titre de la question',
                responses: [
                  { id: 1, label: 'Non', value: 0 },
                  { id: 2, label: 'Oui', value: 1 },
                ],
                selected_id: null,
                metadata: [
                  {
                    type: 'MAJOR',
                    is_required: true,
                    to_justify: false,
                    to_test: false,
                  },
                  {
                    type: 'MINOR',
                    is_required: true,
                    to_justify: false,
                    to_test: false,
                  },
                  {
                    type: 'NOT_APPLICABLE',
                    is_required: true,
                    to_justify: false,
                    to_test: false,
                  },
                ],
              },
            ],
          },
        ],
      }
      this.current_form.data.chapters.splice(chapter_index + 1, 0, chapter)
    },
    remove_chapter(chapter_index) {
      if (this.current_form.data.chapters.length === 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
              'Il est nécessaire que le formulaire contienne au minimum un chapitre.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } else {
        this.current_form.data.chapters.splice(chapter_index, 1)
      }
    },

    // update form -------------------------------------------------------------
    update_form() {
      // type evalaution, type hebergement
      this.action_updateFormEvaluation({
        id: this.current_form.id,
        params: { data: this.data },
      })
        .then(async (response) => {
          if (response.status === 202) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Modification enregistrée.',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })

            // this.current_form = await this.find_correct_form(
            //   response.data,
            //   this.current_type_hebergement_id
            // )

            // console.log('update_form after::: 🔵', this.current_form)
            // this.isLoading = false
          }
        })
        .catch((error) => {
          this.isLoading = false
          console.error(
            'Erreur lors de la recherche des auto-évaluations.',
            error
          )
        })
    },

    getCurrentTypeEvaluationTitle(id) {
      this.action_getTypeEvaluation(id)
        .then((response) => {
          this.currentFormTitle = response.data.title
          this.currentFormType = response.data.type
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>
<style lang="scss" scoped>
// @import '@core/scss/vue/pages/page-auth.scss';
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Parcourir';
}

.invalid-input .vs__dropdown-toggle {
  border-color: #f3706a;
}
</style>
