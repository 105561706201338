var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{class:{ open: _vm.visible },attrs:{"no-body":""},on:{"mouseenter":_vm.collapseOpen,"mouseleave":_vm.collapseClose}},[_c('b-card-header',{class:{ collapsed: !_vm.visible },attrs:{"aria-expanded":_vm.visible ? 'true' : 'false',"aria-controls":_vm.collapseItemID,"role":"tab","data-toggle":"collapse"},on:{"click":function($event){return _vm.updateVisible(!_vm.visible)}}},[_vm._t("header",function(){return [_c('div',{staticClass:"d-flex align-items-center"},[(!_vm.show_action_buttons)?_c('span',{staticClass:"lead collapse-title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_c('span',{staticClass:"lead collapse-title",on:{"click":function($event){$event.stopPropagation();}}},[_c('b-form-group',{staticClass:"nopadding"},[_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-form-input',{on:{"change":function () {
                    _vm.$emit('onTitleChange', _vm.current_title);
                  }},model:{value:(_vm.current_title),callback:function ($$v) {_vm.current_title=$$v},expression:"current_title"}}),_c('b-input-group-append',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                  'Vous pouvez modifier le titre de la section.'
                ),expression:"\n                  'Vous pouvez modifier le titre de la section.'\n                ",modifiers:{"hover":true,"top":true}}],attrs:{"is-text":""}},[_c('i',{staticClass:"las la-info-circle iconMedium"})])],1)],1)],1),(_vm.questions_count)?_c('div',{staticClass:"badge-custom ml-2",staticStyle:{"padding":"4px 8px","background-color":"#eee","border-radius":"4px"}},[_c('small',[_c('b',{staticClass:"text-secondary"},[_vm._v(_vm._s(_vm.questions_count.total_responded_questions !== null ? _vm.questions_count.total_responded_questions : 0))]),_vm._v(" / "),_c('b',{staticClass:"text-success"},[_vm._v(_vm._s(_vm.questions_total))])])]):_vm._e(),(_vm.show_action_buttons)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top.v-primary",value:(
            'Ajouter une section après celle-ci.'
          ),expression:"\n            'Ajouter une section après celle-ci.'\n          ",modifiers:{"hover":true,"top":true,"v-primary":true}}],staticClass:"btn-icon ml-3",attrs:{"variant":"gradient-primary","size":"sm"},on:{"click":function($event){$event.stopPropagation();return (function () {
              _vm.$emit('addSection', {});
            }).apply(null, arguments)}}},[_vm._v(" Ajouter une section "),_c('i',{staticClass:"las la-plus"})]):_vm._e(),(_vm.show_action_buttons)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Supprimer la section actuelle.'),expression:"'Supprimer la section actuelle.'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon ml-1",attrs:{"variant":"gradient-danger","size":"sm"},on:{"click":function($event){$event.stopPropagation();return (function () {
              _vm.$emit('removeSection', {});
            }).apply(null, arguments)}}},[_vm._v(" Supprimer la section "),_c('i',{staticClass:"las la-trash"})]):_vm._e()],1)]})],2),_c('b-collapse',{attrs:{"id":_vm.collapseItemID,"accordion":_vm.accordion,"role":"tabpanel"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('b-card-body',[_vm._t("default")],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }