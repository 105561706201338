<template>
  <b-card
    no-body
    :class="{ open: visible }"
    @mouseenter="collapseOpen"
    @mouseleave="collapseClose"
  >
    <b-card-header
      :class="{ collapsed: !visible }"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="collapseItemID"
      role="tab"
      data-toggle="collapse"
      @click="updateVisible(!visible)"
    >
      <slot name="header">
        <div class="d-flex align-items-center">
          <span class="lead collapse-title" v-if="!show_action_buttons">
            {{ title }}
          </span>
          <span class="lead collapse-title" @click.stop="" v-else>
            <b-form-group class="nopadding">
              <b-input-group class="" size="sm">
                <b-form-input
                  v-model="current_title"
                  @change="
                    () => {
                      $emit('onTitleChange', current_title);
                    }
                  "
                />
                <b-input-group-append
                  is-text
                  v-b-tooltip.hover.top="
                    'Vous pouvez modifier le titre de la section.'
                  "
                >
                  <i class="las la-info-circle iconMedium" />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <!-- <b-form-input
              v-model="current_title"
              @change="
                () => {
                  $emit('onTitleChange', current_title);
                }
              "
            /> -->
          </span>
          <div
            v-if="questions_count"
            style="padding: 4px 8px; background-color: #eee; border-radius: 4px"
            class="badge-custom ml-2"
          >
            <small
              ><b class="text-secondary">{{
                questions_count.total_responded_questions !== null
                  ? questions_count.total_responded_questions
                  : 0
              }}</b>
              /
              <b class="text-success">{{ questions_total }}</b></small
            >
          </div>
          <!-- ajouter la section -->
          <b-button
            v-if="show_action_buttons"
            variant="gradient-primary"
            class="btn-icon ml-3"
            size="sm"
            v-b-tooltip.hover.top.v-primary="
              'Ajouter une section après celle-ci.'
            "
            @click.stop="
              () => {
                $emit('addSection', {});
              }
            "
          >
            Ajouter une section <i class="las la-plus"></i>
          </b-button>
          <!-- supprimer la section -->
          <b-button
            v-if="show_action_buttons"
            variant="gradient-danger"
            class="btn-icon ml-1"
            size="sm"
            v-b-tooltip.hover.top="'Supprimer la section actuelle.'"
            @click.stop="
              () => {
                $emit('removeSection', {});
              }
            "
          >
            Supprimer la section <i class="las la-trash"></i>
          </b-button>
          <!-- <div
            class="d-flex ml-2 align-items-center"
            style="gap: 4px; margin-top: 8px"
          >
          </div> -->
        </div>
      </slot>
    </b-card-header>

    <b-collapse
      :id="collapseItemID"
      v-model="visible"
      :accordion="accordion"
      role="tabpanel"
    >
      <b-card-body>
        <slot />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
  BBadge,
  BButton,
  BFormInput,
  VBTooltip,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
} from 'bootstrap-vue';
import { v4 as uuidv4 } from 'uuid';
import Ripple from 'vue-ripple-directive';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BBadge,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
  },
  props: {
    questions_count: {
      type: Object,
    },
    questions_total: {
      type: Number,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
    },
    addAndDeleteSection: {
      type: String,
      required: false,
    },
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      visible: false,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
      current_title: '',
    };
  },
  computed: {
    accordion() {
      return this.$parent.accordion
        ? `accordion-${this.$parent.collapseID}`
        : null;
    },
    show_action_buttons() {
      return this.addAndDeleteSection === 'false' || !this.addAndDeleteSection
        ? false
        : true;
    },
  },
  created() {
    this.collapseItemID = uuidv4();
    this.visible = this.isVisible;
  },
  mounted() {
    this.current_title = this.title;
  },
  methods: {
    updateVisible(val = true) {
      this.visible = val;
      this.$emit('visible', val);
    },
    collapseOpen() {
      if (this.openOnHover) this.updateVisible(true);
    },
    collapseClose() {
      if (this.openOnHover) this.updateVisible(false);
    },
    change_title() {
      this.$emit('onTitleChange', this.title);
    },
  },
};
</script>

<!-- <style scoped>
::v-deep .badge-custom {
  padding: 4px;
}
</style> -->
